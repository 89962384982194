import React from 'react'
import Link from "gatsby-link"

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import profilePic from '../assets/profile-pic.jpg'
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={profilePic}
          alt={`Ben Partch`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(3),
            height: rhythm(3),
            // added by bpartch
            borderRadius: rhythm(50),
          }}
        />
        <p>Written by <strong><a href="https://benpartch.org/">Ben Partch</a>.</strong> A front end developer who lives and <a href="https://retronetro.com/">works</a> in the Dallas/Ft. Worth metroplex. To learn more <Link to="/about/" title="About">about</Link> him and this site, {' '}follow <a href="https://twitter.com/benpartch">Ben</a> or <a href="https://twitter.com/macyuppie">MacYuppie</a> on Twitter. Or send him <Link to="/contact/" title="Email Me">Email</Link></p>
      </div>
    )
  }
}
export default Bio
