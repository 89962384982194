import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import CopyRight from "../components/copyright"
import Bio from '../components/Bio'
import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>

        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${post.frontmatter.title}  -  ${siteTitle}`}
        />

        <header>
          <h1 style={{ color: 'dodgerblue' }}>{post.frontmatter.title}</h1>
        </header>

        <main>
          <article>
            <span style={{ ...scale(-1 / 5), display: 'block', marginBottom: rhythm(1), marginTop: rhythm(-1), }}>
              {post.frontmatter.date}
            </span>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <hr style={{ marginBottom: rhythm(1), }} />
          </article>

          <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', listStyle: 'none', padding: 0, }}>
            <li>
              {
                previous &&
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              }
            </li>
            <li>
              {
                next &&
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              }
            </li>
          </ul>
          <hr />
        </main>

        <Bio />
       <CopyRight />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
