import React from "react"
import Link from "gatsby-link"
import styled from 'styled-components'

const CopyRight = styled.p`
  padding-top: 0;
  font-size: 1rem;
`;

export default () => (
  <footer>
    <CopyRight>&copy; Copyright { new Date().getFullYear() } <Link to="/" title="MacYuppie">MacYuppie</Link>. All rights reserved.</CopyRight>
  </footer>
)